import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default class corporate extends React.Component {
	render() {
		return (
			<Container className="mBottom">
				<div className="h2 blue">
					WEB DESIGN STEPS
				</div>
				<Row>
					<Col className="text-center">
					<div className="subtitle">Step 1 </div>
					<div className="h4">Research & Inspiration </div>
					<p className="text2 text-center">In the first step, we use well-practiced techniques to develop an intimate knowledge of the business, and the industry in which it operates. That research feeds our creative process, and allows us to arrive at inspired and original ideas that our customers love. </p>
					</Col>

					<Col className="text-center">
					<div className="subtitle">Step 2 </div>
					<div className="h4">Concepts Designs </div>
					<p className="text2 text-center">Time to turn our ideas into your reality - in step two, our designers work passionately to create the concepts that reflect your business in the best possible way. Here we refine our thoughts into form, creating the design that demonstrates your value to your clients. </p>
					</Col>

					<Col className="text-center">
					<div className="subtitle">Step 3 </div>
					<div className="h4">Content Population </div>
					<p className="text2 text-center">Pairing our design with content is the turning point in the process. We transform our design into a framework and populate it with text, graphics and media, providing you with the look and feel of your future site. </p>
					</Col>

					<Col className="text-center">
					<div className="subtitle">Step 4 </div>
					<div className="h4">Website Programming </div>
					<p className="text2 text-center">The site comes together when we pair our design with website development. We use the latest technologies to create a unique experience for the user and enable backend functionality. Our developers know how to make a design come to life using code. </p>
					</Col>

					<Col className="text-center">
					<div className="subtitle">Step 5 </div>
					<div className="h4">Website Testing & Debugging </div>
					<p className="text2 text-center">Before launching any website, we need to make sure that everything is in order. We use a rigorous testing process to ensure that all functionality works as intend and that nothing has been left out. In addition, we test the site for usability, navigation and speed. </p>
					</Col>

					<Col className="text-center">
					<div className="subtitle">Step 5 </div>
					<div className="h4">Website Launch & Site Support </div>
					<p className="text2 text-center">Even once the site is online, launched, accessible, and we have your final sign-off, our work does not end there. We provide excellent after-sales support, making sure that the site is maintained and updated well into the future.	 </p>
					</Col>
				</Row>	
			</Container>
		)
	}
}