import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import img_marketplace from '../images/marketplace.png';

import { motion } from "framer-motion"

export default class Marketplace extends React.Component {
	render() {
		return (
			<Container className="mBottom">
				<h1 class="blue">Marketplace</h1>
				<div className="text-center">
					<motion.img src={img_marketplace} width="940" height="433" alt="Marketplace" 
	                initial={{ opacity: 0, scale: 0.5 }}
	                animate={{ opacity: 1, scale: 1 }}
	                transition={{ duration: 0.5 }} /> 
				</div>	
			</Container>	
		)
	}
}