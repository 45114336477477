import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
export default class Ecommerce extends React.Component {
	render() {
		return (
			<div>
				<Container className="mBottom">
				<h1 className="blue">E-COMMERCE PORTFOLIO</h1>
				<Row>
					<Col className="text-center">
					<div className="h4">RESPONSIVE & MOBILE-FRIENDLY</div>
					<p className="text2 text-center">A mobile-friendly website will ensure your customers will have an enjoyable and easy to navigate online experience.
					We make websites that adapt the layout to the viewing environment.</p>
					</Col>

					<Col className="text-center">
					<div className="h4">ATTRACTIVE & MODERN LOOK</div>
					<p className="text2 text-center">
					Each of our clients gets a unique, custom design.
					Our team of experts will make you a creaitve, visually stunning and practical website.</p>
					</Col>

					<Col className="text-center">
					<div className="h4">SIMPLE & CLEAR NAVIGATION</div>
					<p className="text2 text-center">
					We give you a clear and concise website navigation, which is very important to keep visitors from exiting your website.</p>
					</Col>

					<Col className="text-center">
					<div className="h4">INTERACTIVE & ENGAGING</div>
					<p className="text2 text-center">
					We work hand-in-hand with our clients to provide engaging web experiences that give visitors what they’re looking for, websites that are compelling, accurately reflect brand personality, websites that sell.</p>
					</Col>

					<Col className="text-center">
					<div className="h4">USER FRIENDLY</div>
					<p className="text2 text-center">
					For your website to be successful, it should not only look good but also provide a seamless user experience for visitors. We provide a good usability, that sets your website apart from competition.</p>
					</Col>

					<Col className="text-center">
					<div className="h4">RELIABILITY & SECURITY</div>
					<p className="text2 text-center">
					We appreciate how important a secure, reliable and fast solution is to your business. The best part is that you don’t even have to worry about what we’re doing – because you can rest assured that we are providing the highest quality in security as standard</p>
					

					</Col>
				</Row>
				</Container>

				<div className="grey_block mBottom">
					<Container>
					<div className="h1">E-COMMERCE PORTFOLIO</div>
					<Row>
						<Col className="text-center">
						<div className="h4">CUSTOM MODULE DEVELOPMENT</div>
						<p className="text2 text-center">If you require functionality that is not fulfilled by one of Intelex`s contributed modules we can create a custom module from scratch to fit your needs. </p>
						</Col>

						<Col className="text-center">
						<div className="h4">HIGH PERFORMANCE </div>
						<p className="text2 text-center">We ensure the large, feature rich & heavily visited websites are optimised by working to a set of rules designed to speed up web pages load time and are able to handling a high volume of traffic. </p>
						</Col>

						<Col className="text-center">
						<div className="h4">3RD PARTY INTEGRATIONS </div>
						<p className="text2 text-center">We will help you meet business goals and transform your website into a dynamic and sophisticated consumer experience by integrating eCommerce websites with ERP, Accounting systems and CRM tools. </p>
						</Col>

						<Col className="text-center">
						<div className="h4">SEARCH ENGINE OPTIMIZED </div>
						<p className="text2 text-center">Our eCommerce websites are designed with search engines in mind so customers can find you. We automatically optimize your site and product pages so you’ll have everything you need to start ranking for search results. </p>
						</Col>

						<Col className="text-center">
						<div className="h4">POWERFUL FILTERED PRODUCT SEARCH </div>
						<p className="text2 text-center">Give shoppers a faster way to find exactly what they want. We offer filtered product search that helps you maximize exposure across all of your inventory and create more buying opportunities. </p>
						</Col>

						<Col className="text-center">
						<div className="h4">MULTI-LINGUAL / MULTI-CURRENCY / MULTI-PAYMENT / MULTI-SHIPPING </div>
						<p className="text2 text-center">We offer a variety of payment options, languages and currency types, shipping choices that shoppers want and easily handle a full spectrum of shipping situations ranging from simple to complex.  </p>
						</Col>
					</Row>
					</Container>	
				</div>
			</div>
		)
	}
}