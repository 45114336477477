import React from 'react'
import { NavLink, Outlet } from 'react-router-dom'
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from './images/logo.png';
export default class Layout extends React.Component {
	render() {
		return (
			<>
			<Navbar expand="lg" sticky="top" className="menu_top">
			<div className="container">
				<Navbar.Brand href="/"><img src={logo} className="App-logo" alt="logo" /></Navbar.Brand>
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="nav">
					<Nav className="menu_top_nav">
						<NavLink 
							to="/" 
							className={({ isActive }) => [ isActive ? "active" : "" ] }>Home 
							<span></span>
						</NavLink>
						<NavLink to="/OurProducts"
						className={({ isActive }) => [ isActive ? "active" : "" ] }>Our Products
						  <span></span>
						  </NavLink>
						<NavLink to="/Ecommerce"
						className={({ isActive }) => [ isActive ? "active" : "" ] }>E-commerce
						  <span></span>
						  </NavLink>
						<NavLink to="/Corporate"
						className={({ isActive }) => [ isActive ? "active" : "" ] }>Corporate
						  <span></span>
						  </NavLink>
						<NavLink to="/Seo"
						className={({ isActive }) => [ isActive ? "active" : "" ] }>Seo
						  <span></span>
						  </NavLink>
						<NavLink to="/Marketplace"
						className={({ isActive }) => [ isActive ? "active" : "" ] }>Marketplace
						  <span></span>
						  </NavLink>
						<NavLink to="/Contacts"
						className={({ isActive }) => [ isActive ? "active" : "" ] }>Contacts
						  <span></span>
						  </NavLink>
					</Nav>	
				</Navbar.Collapse>
			</div>
			</Navbar>

			<Outlet />
			</>
		)
	}
}
