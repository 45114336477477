import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import svitstyle from '../images/img_svitstyle.jpg';

export default class OurProducts extends React.Component {
	render() {
		return (
			<>
			<Container className="text mBottom">
			<h1 className="blue mb-4">POWERFUL COMPANY WITH OWN PRODUCTS</h1>
				<Row>
				<Col>
					<p>We love novelties. Especially those that make online or offline business more successful. So besides making our clients’ dreams come true, we carry out our own projects as well which can be used for our further services or individually.</p>
				</Col>

				<Col><p>Our projects are made with love and aim to make user’s shopping experience easier, better and secure. We hope you’ll enjoy using our products as much as we enjoy creating them.</p>
				</Col>
				</Row>
			</Container>

			<section className="grey_block mBottom">
				<Container>
					<div className="h2 blue mb-4">SvitStyle - niche marketplace of women’s clothing, shoes and accessories</div>
					<img src={svitstyle} alt="logo" />
					SvitStyle brings all the products from hundreds of stores to one place. With the revolutionary algorithm that takes into account personal characteristics and preferences (age, income, past search patterns, etc.) and crowdsourcing preferences (what other similar people liked), SvitStyle brings the most suitable products for that particular user to the top. With its color selection and category matching algorithm SvitStyle offers a great selection of matching and complementary products from multiple stores.
				</Container>	
			</section>
			</>
		)
	}
}