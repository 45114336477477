import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from '../Carousel.jsx';
import { motion } from "framer-motion"
import { useInView } from "framer-motion"

const textAnimation = {
	hidden: {
		x: -100,
		opacity: 0,
	},
	visible: custom => ({
		x: 0,
		opacity: 1,
		transition: { delay:custom *0.2  },  
	}),
}


const Home = () => {
		return (
		<div className="mBottom" 
		>
			<motion.div 
		initial="hidden"
		whileInView="visible"
		 >
			<Carousel />   
			<motion.h3 custom={1} variants={textAnimation} className="text-center">We are Intelex! A full service web design company with over 14 years of experience, operating out of Toronto, Canada.</motion.h3>
			<motion.h4 custom={1.5} variants={textAnimation} className="text2 text-center mb-4">Our goal is to grow your business. We provide all of the high quality services and solutions that you need to get your business online or take it to the next level.</motion.h4>
		</motion.div>
		<motion.Container 
		className="text container"
		initial="hidden"
		whileInView="visible"
		 >
			<Row>
			<Col>
				<motion.p custom={2} variants={textAnimation}>We have successfully developed more than 500 projects, giving us the experience you need to get where you need to go.</motion.p>
				<motion.p custom={3} variants={textAnimation}>To us, every business is unique, and each demands exclusive and unique design. We are a team of well-practiced, efficient professionals.</motion.p>
				<motion.p custom={4} variants={textAnimation}>Our practiced strategies for surfacing the requirements of your business and the needs of your customers allow us to create stunning and effective sites and marketing campaigns.</motion.p>
			</Col>
			<Col>
				<motion.p custom={2} variants={textAnimation}>We offer services in site, graphic and logo design, website and e-commerce development, branding and marketing, site hosting and security, and more.</motion.p>
				<motion.p custom={3} variants={textAnimation}>Our experts will help you bring your creative ideas to life, developing the best sites and strategies to meet your business needs.</motion.p>
				<motion.p custom={4} variants={textAnimation}>We can help you define your unique corporate identity, develop custom online marketing strategies, drive traffic to your website, and promote your business online.</motion.p>
			</Col>
			<Col>
				<motion.p custom={2} variants={textAnimation}>We are a global provider of design services, and we strive to build long lasting relationships with all of our clients. When you use Intelex, you get a team of experienced web developers, designers and marketers with expertise in a wide range of industry verticals, including manufacturing, medicine, educations, and banking services.</motion.p>
				<motion.p custom={3} variants={textAnimation}>We have the knowledge necessary to acquire a deep understanding and connection with your business. If you want to get the next level you need to back your business with the best in the biz - Intelex.</motion.p>
			</Col>
			</Row>
		</motion.Container>
		</div>
		)
	}
export default Home;
