import * as React from 'react';

import { Routes, Route } from 'react-router-dom';

import Layout from './Layout';
import Home from './pages/Home';
import OurProducts from './pages/OurProducts';
import Ecommerce from './pages/Ecommerce';
import Corporate from './pages/Corporate';
import Seo from './pages/Seo';
import Marketplace from './pages/Marketplace';
import Contacts from './pages/Contacts';

export default class Header extends React.Component {
	render() {
		return (
			<div>
			    <Routes>
				    <Route path="/" element={<Layout />}>
				      <Route index element={<Home />} />
				      <Route path="OurProducts" element={<OurProducts />} />
				      <Route path="Ecommerce" element={<Ecommerce />} />
				      <Route path="Corporate" element={<Corporate />} />
				      <Route path="Seo" element={<Seo />} />
				      <Route path="Marketplace" element={<Marketplace />} />
				      <Route path="Contacts" element={<Contacts />} />
				    </Route>
			    </Routes>
    </div>
		)
	}
}