import * as React from 'react';
import { GrFacebookOption } from "react-icons/gr";
import { TiSocialLinkedin } from "react-icons/ti";
import { RiTwitterXLine } from "react-icons/ri";
import { PiPinterestLogoBold } from "react-icons/pi";
import { RiLinkedinFill } from "react-icons/ri";

export default function Socials() {
  return (
 	  <div>
      <GrFacebookOption />
      <TiSocialLinkedin />
      <RiTwitterXLine />
      <PiPinterestLogoBold />
      <RiLinkedinFill />
    </div>
  );
}