import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
export default class Contacts extends React.Component {
	render() {
		return (
			<Container className="mBottom">
				<h1 class="blue">Contacts</h1>
			</Container>
		)
	}
}