import { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Carousel from 'react-bootstrap/Carousel';
import slide1 from './images/slide1.jpg';
import slide2 from './images/slide2.jpg';
import slide3 from './images/slide3.jpg';
import { motion } from "framer-motion"
import { useInView } from "framer-motion"

function Slider() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const slideAnimation = {
  hidden: {
    scale: 0.5,
    opacity: 0,
  },
  visible: custom => ({
    scale: 1,
    opacity: 1,
    transition: { duration: 0.5  },  
  }),
}

  return (
    <div className="grey_block2 mBottom">
    <motion.Container 
    initial="hidden"
    whileInView="visible">
    <Carousel activeIndex={index} onSelect={handleSelect} data-bs-theme="dark">
      <Carousel.Item>
        <motion.img src={slide1} custom={1} variants={slideAnimation} width="940" height="433" alt=""  />        
      </Carousel.Item>
      <Carousel.Item>
        <img src={slide2} custom={1} variants={slideAnimation} width="940" height="433" alt="" 
        />        
      </Carousel.Item>
      <Carousel.Item>
        <img src={slide3}  custom={1} variants={slideAnimation} width="940" height="433" alt="" />        
      </Carousel.Item>
    </Carousel>
    </motion.Container>
    </div>
  );
}

export default Slider;