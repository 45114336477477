import 'bootstrap/dist/css/bootstrap.min.css';
import './style.scss';
import Socials from './Socials.js';
import Header from './Header.js';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import logo_white from './images/logo_bottom.png';

const App = () => {
  return (
    <div>
      <header>
        <div className="header">
          <Container>
            <Socials />            
          </Container> 
        </div>
      </header>        
      <Header />          
             

      <footer>
        <Container>
          <Row>
            <Col>
            <img src={logo_white} alt="logo" />
            </Col>
            <Col>
              <ul className="bottom_menu">
                <li className="top_level">INFO</li>
                <li className="sub_level">hosting requirements</li>
                <li className="sub_level">hosting ua</li>
                <li className="sub_level">ssl certificate</li>
                <li className="sub_level">printing</li>
                <li className="sub_level">terms of services</li>
              </ul>
            </Col>
            <Col>
              <ul className="bottom_menu">
                <li className="top_level">TERMS</li>
                <li className="sub_level">what we support</li>
                <li className="sub_level">refund policy</li>
                <li className="sub_level">privacy and security</li>
                <li className="sub_level">web development standards</li>
                <li className="sub_level">website maintenance</li>
              </ul>
            </Col>
            <Col>
              <ul className="bottom_menu">
                <li className="top_level">ABOUT</li>
                <li className="sub_level">news</li>
                <li className="sub_level">blog</li>
                <li className="sub_level">testimonials</li>
              </ul>
            </Col>
          </Row>
        </Container>
      </footer>
    </div>
  );
}

export default App;
